<template>
  <b-card-code no-body>
    <b-card-body>
      <b-row>
        <b-col md="2">
          <b-form-group label="Pesquisar Por" label-for="opcao">
            <b-form-select id="opcao" :options="tipoOpcoes" v-model="pesquisar.tipoOpcoes" />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group label="Descrição" label-for="descricao">
            <b-form-input
              id="descricao"
              placeholder="Descrição"
              v-model="pesquisar.descricao"
              v-on:keydown.enter="carregarGrid"
            />
          </b-form-group>
        </b-col>
        <b-col md="auto">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            style="margin-top: 20px; width: 160px"
            @click="pesquisarDocumento()"
          >
            Pesquisar
          </b-button>
        </b-col>
        <b-col md="auto" align="right">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            style="margin-top: 20px; width: 160px"
            @click="novoRegistro()"
          >
            Novo Cadastro
          </b-button>
        </b-col>
      </b-row>
    </b-card-body>
    <b-table
      v-show="items.length > 0"
      striped
      responsive
      class="position-relative"
      :per-page="pesquisar.perPage"
      :items="items"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :busy="isBusy"
      :current-page="pesquisar.currentPage"
    >
      <template v-slot:table-busy>
        <div class="text-center my-2">
          <b-spinner class="align-middle mr-1"></b-spinner>
          <strong>Carregando...</strong>
        </div>
      </template>
      <template #cell(opcoes)="row">
        <b-dropdown variant="link" no-caret toggle-class="p-0" left :popper-opts="{ positionFixed: true }">
          <template #button-content>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon"
              title="Este botão contém as opções disponíveis para este registro"
            >
              <feather-icon icon="SettingsIcon" />
            </b-button>
          </template>
          <b-dropdown-item @click="excluir(row.item.id)">
            <feather-icon icon="XIcon" size="16" />
            <span class="align-middle ml-50">Excluir</span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
      <template #cell(originalName)="row">
        <a href="#" @click="downloadArquivo(row.item.id)"> {{ row.item.originalName }}</a>
      </template>
    </b-table>

    <b-card-body v-if="items.length > 0" class="d-flex justify-content-between flex-wrap pt-0">
      <!-- page length -->
      <b-form-group
        style="align-items: center"
        label="Por Página"
        label-cols="6"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select id="perPageSelect" v-model="pesquisar.perPage" size="sm" inline :options="pageOptions" />
      </b-form-group>

      <!-- pagination -->
      <b-pagination
        v-model="pesquisar.currentPage"
        :total-rows="totalRows"
        :per-page="pesquisar.perPage"
        first-number
        last-number
        prev-class="prev-item"
        next-class="next-item"
        class="mb-0"
      >
        <template #prev-text>
          <feather-icon icon="ChevronLeftIcon" size="18" />
        </template>
        <template #next-text>
          <feather-icon icon="ChevronRightIcon" size="18" />
        </template>
      </b-pagination>
    </b-card-body>

    <!-- Modal Cadastro -->
    <b-modal id="modal-cadastro" ref="modal-cadastro" centered title="Cadastro" no-close-on-backdrop>
      <validation-observer ref="formOrgao">
        <b-form>
          <b-row>
            <b-col md="12">
              <b-form-group label="Nome do Documento" label-for="nome">
                <validation-provider #default="{ errors }" name="Nome do Documento" rules="required">
                  <b-form-input id="nome" type="text" placeholder="Nome do Documento" v-model="dados.nome" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12">
              <b-form-file
                ref="file"
                accept=".docx"
                placeholder="Escolha um arquivo"
                drop-placeholder="Deixe seu arquivo aqui..."
              />
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>

      <template #modal-footer="{}">
        <b-button variant="outline-secondary" @click="$bvModal.hide('modal-cadastro')"> Fechar </b-button>
        <b-button variant="primary" @click="salvar" :disabled="loading">
          {{ !loading ? 'Salvar' : '' }}
          <b-spinner small v-if="loading" />
        </b-button>
      </template>
    </b-modal>
  </b-card-code>
</template>

<script>
  import BCardCode from '@core/components/b-card-code/BCardCode.vue'
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import { required } from '@validations'
  import Ripple from 'vue-ripple-directive'
  import useJwt from '@/auth/jwt/useJwt'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

  export default {
    components: {
      BCardCode,
      ValidationProvider,
      ValidationObserver,
    },
    props: {
      generalData: {
        type: Object,
        default: () => {},
      },
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        isBusy: false,
        pesquisar: { tipoOpcoes: 1, descricao: '', perPage: 10, currentPage: 1 },
        required,
        tipoOpcoes: [{ value: 1, text: 'Nome' }],
        pageOptions: [10, 25, 50, 100],
        totalRows: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        fields: [
          { key: 'opcoes', label: 'Opções' },
          { key: 'nome', label: 'Nome', sortable: true },
          { key: 'originalName', label: 'Documento' },
        ],
        items: [],
        dados: {},
        loading: false,
      }
    },
    computed: {
      sortOptions() {
        return this.fields.filter((f) => f.sortable).map((f) => ({ text: f.label, value: f.key }))
      },
      ambiente() {
        return process.env.VUE_APP_BASE_URL
      },
    },
    mounted() {
      this.carregarGrid()
    },
    methods: {
      pesquisarDocumento() {
        this.pesquisar = { ...this.pesquisar, currentPage: 1 }
        this.carregarGrid()
      },
      carregarGrid() {
        this.isBusy = true
        useJwt
          .pesquisar('cadastro/documentoModelo/Getpesquisar', {
            ...this.pesquisar,
            produtoId: this.generalData.id,
          })
          .then((response) => {
            this.items = response.data.dados
            this.totalRows = response.data.total
          })
          .catch((error) => {
            console.error(error)
          })
          .finally(() => {
            this.isBusy = false
          })
      },
      novoRegistro() {
        this.dados = {}
        this.$refs['modal-cadastro'].show()
      },
      excluir(item) {
        let filtro = {
          id: item,
          produtoId: this.generalData.id,
        }

        useJwt.post(`cadastro/documentoModelo/GetdocumentoModeloCheckList`, filtro).then((response) => {
          if (response.data.length > 0)
            this.$message.error('Exclusão não permitida! O documento selecionado está vinculado a um check-list.')
          else {
            this.$swal({
              title: 'Atenção!',
              text: 'Deseja realmente excluir o documento selecionado?',
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: 'Sim!',
              cancelButtonText: 'Não!',
              customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-danger ml-1',
              },
              buttonsStyling: false,
            }).then((result) => {
              if (result.value) {
                useJwt
                  .delete(`cadastro/documentoModelo`, item)
                  .then((response) => {
                    this.carregarGrid()
                  })
                  .catch((error) => {
                    console.error(error)
                    this.$toast(
                      {
                        component: ToastificationContent,
                        props: {
                          title: error.response.data.error,
                          icon: 'BellIcon',
                          variant: 'danger',
                        },
                      },
                      {
                        position: 'top-right',
                        timeout: 8000,
                      },
                    )
                  })
              }
            })
          }
        })
      },
      editar(item) {
        useJwt
          .edit(`cadastro/documentoModelo`, item)
          .then((response) => {
            this.dados = response.data
            this.$refs['modal-cadastro'].show()
          })
          .catch((error) => {
            console.error(error)
          })
      },
      salvar(bvModalEvt) {
        bvModalEvt.preventDefault()
        this.$refs.formOrgao.validate().then((success) => {
          if (success) {
            this.loading = true
            if (!this.dados.id) {
              const formData = new FormData()
              formData.append('nome', this.dados.nome)
              formData.append('produtoId', this.generalData.id)
              formData.append('file', this.$refs.file.files[0])

              useJwt
                .postDocumento(`cadastro/documentoModelo`, () => {}, formData)
                .then(() => {
                  this.$swal({
                    title: 'Atenção!',
                    text: 'Documento salvo com sucesso',
                    icon: 'success',
                    customClass: {
                      confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                  }).then((result) => {
                    if (result.value) {
                      this.loading = !this.loading
                      this.$refs['modal-cadastro'].hide()
                      this.carregarGrid()
                    }
                  })
                })
                .catch((error) => {
                  console.error(error)
                  this.loading = !this.loading
                  this.$toast(
                    {
                      component: ToastificationContent,
                      props: {
                        title: 'Atenção!',
                        text: error.response.data.error || 'Erro ao anexar o documento.',
                        icon: 'BellIcon',
                        variant: 'danger',
                      },
                    },
                    {
                      position: 'top-right',
                      timeout: 8000,
                    },
                  )
                })
            }
          }
        })
      },
      downloadArquivo(item) {
        useJwt
          .downloadDocumento(`cadastro/documentoModelo/download`, item)
          .then((response) => {
            window.open(response.data.s3url)
          })
          .catch((error) => {
            console.error(error)
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: error.response.data.error,
                  icon: 'BellIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'top-right',
                timeout: 8000,
              },
            )
          })
      },
    },
  }
</script>
